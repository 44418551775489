import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const validateEmail = (errorMessage: string) => yup.string().email(errorMessage);

const validatePassword = (
  errorMessageMinLength: string,
  errorMessageMaxLength: string,
  errorMessageAtLeastOneNumber: string,
  errorMessageAtLeastOneLowercaseChar: string,
  errorMessageAtLeastOneUppercaseChar: string
) =>
  yup
    .string()
    .min(6, errorMessageMinLength)
    .max(16, errorMessageMaxLength)
    .matches(/\d/g, errorMessageAtLeastOneNumber)
    .matches(/[a-z]/g, errorMessageAtLeastOneLowercaseChar)
    .matches(/[A-Z]/g, errorMessageAtLeastOneUppercaseChar);

const validateRepeatPassword = (errorMessage: string, ref: string = 'password') =>
  yup.string().oneOf([yup.ref(ref), null], errorMessage);

const required = (validation: yup.AnySchema, errorMessage: string) =>
  validation.required(errorMessage);

const useValidation = () => {
  const { t } = useTranslation();

  return {
    validateEmail: () => validateEmail(t('form_validation.email_not_valid')),

    validatePassword: () =>
      validatePassword(
        t('form_validation.min_length', { minChars: 6 }),
        t('form_validation.max_length', { maxChars: 16 }),
        t('form_validation.one_number'),
        t('form_validation.one_letter_lowercase'),
        t('form_validation.one_letter_uppercase')
      ),

    validateRepeatPassword: (ref?: string) =>
      validateRepeatPassword(t('form_validation.dont_match'), ref),

    required: (validation: yup.AnySchema) => required(validation, t('form_validation.required')),
  };
};

export default useValidation;
