import isArray from 'lodash/isArray';
import { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import routes from './imports/routes';
import Layout from './pages/Layout';

const App = () => (
  <Router>
    <Routes>
      {routes.map((route) => {
        const { path, name } = route;

        return (
          <Fragment key={name}>
            {isArray(path) ? (
              path.map((path) => (
                <Route path={path} key={path} element={<Layout route={route} />} />
              ))
            ) : (
              <Route path={path} element={<Layout route={route} />} />
            )}
          </Fragment>
        );
      })}
    </Routes>
  </Router>
);
export default App;
