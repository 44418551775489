import { collection, getDocs, query, where } from 'firebase/firestore';
import flatten from 'lodash/flatten';
import { ipfsGateway } from '../imports/constants';

import { db } from '../imports/firebase';

import type { Contract, Nft } from '../imports/types';

const getCompanies = async () => {
  const contractsQuerySnapshot = await getDocs(collection(db, 'contracts'));

  const contracts: Contract[] = [];

  contractsQuerySnapshot.forEach((doc) => {
    contracts.push(doc.data() as Contract);
  });

  const nfts: Nft[][] = await Promise.all(
    contracts.map(async (contract) =>
      (
        await getDocs(collection(db, `contracts/${contract.id}/nfts`))
      ).docs.map((doc) => doc.data() as Nft)
    )
  );

  const contractsGroupedByCompany = contracts.reduce(
    (previousValue: any, currentValue, currentIndex: number) => {
      const value = { ...currentValue, nfts: nfts[currentIndex] };

      return {
        ...previousValue,
        [value.owner]: (previousValue[value.owner] || []).concat(value),
      };
    },
    {}
  );

  const companies = await Promise.all(
    Object.keys(contractsGroupedByCompany).map(async (companyAddress) => {
      const companyQuery = query(collection(db, 'users'), where('address', '==', companyAddress));
      const companyQuerySnapshot = await getDocs(companyQuery);

      const company: any[] = [];

      companyQuerySnapshot.forEach((doc) => {
        company.push(doc.data() as any);
      });

      const nftsOfContract: Array<Nft & { contractId: string }> = flatten(
        contractsGroupedByCompany[companyAddress].map((contract: any) =>
          contract.nfts.map((nft: Nft) => ({ ...nft, contractId: contract.id }))
        )
      );

      return {
        id: company[0].uid,
        name: company[0].company,
        image: company[0].profilePhoto,
        nfts: nftsOfContract.map((nft) => ({
          ...nft,
          image: nft.image.replace('ipfs://', ipfsGateway || 'https://ipfs.io/ipfs/'),
        })),
      };
    })
  );

  return companies;
};

const getCompany = async (id: string) => {
  const companies = await getCompanies();

  return companies.find((company) => company.id === id);
};

export { getCompanies, getCompany };
