import { AnimatePresence } from 'framer-motion';
import type { ReactNode } from 'react';
import { Portal } from 'react-portal';

import Icon from '../icon/Icon';
import Typography from '../typography/Typography';

import { IconClose } from '../../assets/icons';

type OverlayDialogProps = {
  isOpen: boolean;
  title: string;
  handleClose: () => void;
  children: ReactNode;
};

const OverlayDialog = ({ isOpen, title, handleClose, children }: OverlayDialogProps) => (
  <AnimatePresence>
    {isOpen && (
      <Portal>
        <div className="fixed top-0 left-0 z-10 flex min-h-screen w-screen items-center justify-center text-white">
          <div
            role="presentation"
            className="absolute top-0 left-0 h-full w-full bg-black opacity-90"
            onClick={handleClose}
          />
          <div className="absolute top-1/2 w-[calc(100%-(1rem*2))] -translate-y-1/2 rounded-3xl bg-dialog p-4">
            <header className="absolute top-0 left-0 flex w-full -translate-y-full items-center justify-between py-4">
              <Typography as="h2" size="xl">
                {title}
              </Typography>
              <button
                type="button"
                className="ml-auto flex h-7 w-7 shrink-0 cursor-pointer items-center justify-center rounded-full bg-dialog"
                onClick={handleClose}
              >
                <Icon icon={IconClose} color="white" />
              </button>
            </header>
            {children}
          </div>
        </div>
      </Portal>
    )}
  </AnimatePresence>
);

export default OverlayDialog;
