import type { ReactElement } from 'react';
import { Children, Fragment } from 'react';

import { Popover, PopoverPosition } from 'react-tiny-popover';

type CustomPopoverProps = {
  isOpen: boolean;
  positions: PopoverPosition[];
  content: any;
  children: ReactElement;
  className?: string;
};

const CustomPopover = ({
  isOpen,
  positions,
  content,
  children,
  className = '',
}: CustomPopoverProps) => {
  const resolvedContent = content.type === Fragment ? content.props.children : content;

  return (
    <Popover
      isOpen={isOpen}
      positions={positions}
      content={
        <ul
          className={`${className} flex w-[11.43rem] flex-col space-y-5 rounded-xl bg-popover py-8 px-4 shadow-xl`}
        >
          {Children.map(resolvedContent, (child, index) => (
            <Fragment key={index}>
              <li className="text-white">{child}</li>
              <hr className="!mt-0.5 h-0.5 bg-white" />
            </Fragment>
          ))}
        </ul>
      }
    >
      {children}
    </Popover>
  );
};

export default CustomPopover;
