import { useTranslation } from 'react-i18next';

import Image from '../image/Image';
import Typography from '../typography/Typography';

import { IconMore } from '../../assets/icons';

type CardNftProps = {
  name: string;
  image: string;
  companyName: string;
};

const CardNft = ({ image, name, companyName }: CardNftProps) => {
  const { t } = useTranslation();

  return (
    <article className="border-glass relative h-full w-full overflow-hidden rounded-3xl border bg-dark shadow-2xl">
      <Image src={image} alt={name} className="absolute h-full w-full object-cover" />
      <div className="bg-glass border-glass absolute left-1/2 bottom-0 mx-auto mb-8 flex h-20 w-[12.31rem] -translate-x-1/2 items-center justify-between rounded-2xl px-2">
        <div className="grow px-4">
          <Typography as="h2" color="white">
            {name}
          </Typography>
          <Typography as="h2" size="xs" color="white">
            {companyName}
          </Typography>
        </div>
        <button type="button" aria-label={t('more')} onClick={() => {}}>
          <IconMore className="h-5 w-5 rotate-90 fill-white" />
        </button>
      </div>
    </article>
  );
};

export default CardNft;
