import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { EffectCards } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CardNft, Image, Typography } from '../../components';

import { getCompany } from '../../utils/getCompanies';

import { IconLoading, IconMore } from '../../assets/icons';
import { ImageShare } from '../../assets/images';

import type { Company as CompanyType } from '../../imports/types';

import 'swiper/css';
import 'swiper/css/effect-cards';

const Company = () => {
  const { t } = useTranslation();

  const { id: companyId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState<CompanyType | undefined>();

  const fetchCompany = async () => {
    if (companyId) {
      setIsLoading(true);

      const company = await getCompany(companyId);
      setCompany(company);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  return isLoading ? (
    <div className="flex h-full w-full items-center justify-center">
      <IconLoading className="h-12 w-12 animate-spin text-white" />
    </div>
  ) : (
    <>
      {company ? (
        <>
          <div className="absolute h-1/5 w-full bg-blue" />
          <div className="absolute top-[20%] h-4/5 w-full px-2">
            <header className="flex h-32 items-start justify-between py-4">
              <button type="button" aria-label={t('share')} onClick={() => {}}>
                <Image src={ImageShare} alt={t('share')} className="h-5 w-5 fill-white" />
              </button>
              <div className="-translate-y-1/2">
                <div className="h-[9.37rem] w-[9.37rem] overflow-hidden rounded-full border border-white/50 bg-dark">
                  {company.image && (
                    <Image
                      src={company.image}
                      alt={company.name}
                      className="h-full w-full object-cover"
                    />
                  )}
                </div>
                <Typography
                  as="h1"
                  color="white"
                  weight="semibold"
                  className="mt-4 text-center text-[1.37rem]"
                >
                  {company.name}
                </Typography>
              </div>
              <button type="button" aria-label={t('more')} onClick={() => {}}>
                <IconMore className="h-5 w-5 rotate-90 fill-white" />
              </button>
            </header>
            <div className="flex h-16 items-center justify-center">
              <Typography as="p" size="lg" color="white" className="text-center">
                {`${company.nfts.length} ${t('nfts', { count: company.nfts.length })}`}
              </Typography>
            </div>
            <SimpleBar className="h-[calc(100%-12rem)] w-full overflow-x-hidden">
              <div className="flex min-h-full flex-col px-8 py-4">
                {/* <Typography as="p" color="white">
              {company.description}
            </Typography> */}
                {company.nfts.length > 0 ? (
                  <Swiper
                    effect="cards"
                    grabCursor
                    modules={[EffectCards]}
                    className="mt-8 h-[322px] w-[267px]"
                  >
                    {company.nfts.map((nft) => {
                      const { id, name, image, contractId } = nft;

                      return (
                        <SwiperSlide key={name}>
                          <Link to={`/nft/${id}?contract=${contractId}`}>
                            <CardNft image={image} name={name} companyName={company.name} />
                          </Link>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                ) : (
                  <div className="flex grow items-center justify-center">
                    <Typography color="white" className="text-center">
                      {t('no_nft')}
                    </Typography>
                  </div>
                )}
              </div>
            </SimpleBar>
          </div>
        </>
      ) : (
        <div className="flex h-full w-full items-center justify-center px-5">
          <Typography color="white" className="text-center">
            {t('company_not_found')}
          </Typography>
        </div>
      )}
    </>
  );
};

export default Company;
