import { doc, DocumentData, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-scanner';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Typography } from '../../components';

import mintAbi from '../../abi/mint';
import { firebaseEndpoint } from '../../imports/constants';
import { auth, db } from '../../imports/firebase';
import { getParametersFromUrl, getWallet } from '../../imports/utils';
import ClaimNft from '../../utils/claimNft';
import sdk from '../../utils/pablockSdk';

import { IconLoading } from '../../assets/icons';
import { capitalizeFirstLetter } from '../../utils/common';

const Redeem = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();

  const wallet = getWallet();

  const [isLoading, setIsLoading] = useState(false);

  const redeem = async (id: string, key: string) => {
    try {
      if (wallet) {
        setIsLoading(true);

        const { address, privateKey } = wallet;

        const { data: claimData } = await ClaimNft(address, id, key);

        if (claimData.error) {
          toast.dismiss();
          toast.error(claimData.error, { toastId: 'claim-data-error' });
          setIsLoading(false);
          return;
        }

        if (!sdk.isInitialized()) {
          await sdk.init();
        }

        sdk.setPrivateKey(privateKey);

        const docRef = doc(db, 'contracts', id);
        const docSnapshot = await getDoc(docRef);
        let contract: DocumentData | null = null;

        if (!docSnapshot.exists()) {
          toast.dismiss();
          toast.error(t('nft_claim_error'), { toastId: 'claim-error' });
          setIsLoading(false);
          return;
        }

        contract = docSnapshot.data();

        const tokenId = contract.qrCodes.find(
          (qrCode: { id: number; key: string }) => qrCode.key === key
        ).id;

        const metaTx = await sdk.prepareTransaction(
          {
            address: contract.address,
            abi: mintAbi,
            name: capitalizeFirstLetter(contract.name).replace(/\s/g, ''),
            version: '1.0',
          },
          'mint',
          [address, claimData.signature, claimData.rightAddress, claimData.proof, tokenId]
        );

        const requestId = await sdk.executeAsyncTransaction(metaTx, {
          webhookUrl: `${firebaseEndpoint}/saveReceipt`,
          verbose: true,
        });

        const interval = setInterval(async () => {
          const transaction = await sdk.getMetaTxStatus(requestId);

          if (transaction.status === 'success' || transaction.status === 'failed') {
            if (transaction.status === 'success') {
              toast.dismiss();
              toast.success(t('nft_claim_success'));
              navigate('/nfts');
            }

            if (transaction.status === 'failed') {
              toast.dismiss();
              toast.error(t('nft_claim_error_already_redeemed'), {
                toastId: 'already-redeemed-error',
              });
              setIsLoading(false);
            }

            clearInterval(interval);
          }
        }, 1000);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(t('nft_claim_error', { toastId: 'claim-error' }));
      setIsLoading(false);
    }
  };

  const handleScan = (data: any) => {
    if (data) {
      const { text } = data;

      const { id, key } = getParametersFromUrl(text);

      if (id && key && !isLoading) {
        redeem(id, key);
        return;
      }

      if (search) {
        toast.error(t('invalid_qr_code'), { toastId: 'invalid-qr-code' });
      }
    }
  };

  const handleError = (error: any) => {
    if (error.toString() === 'NotAllowedError: Permission denied') {
      toast.error(t('qr_code_scanner_permissions_error'), { toastId: 'permissions-error' });
    }
  };

  useEffect(() => {
    (async () => {
      await new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          unsubscribe();
          resolve(user);
        }, reject);
      });

      const { id, key } = getParametersFromUrl(search);

      if (id && key) {
        redeem(id, key);
        return;
      }

      if (search) {
        toast.error(t('invalid_qr_code'), { toastId: 'invalid-qr-code' });
      }
    })();
  }, []);

  return isLoading ? (
    <div className="flex h-full w-full flex-col items-center justify-center p-5">
      <IconLoading className="h-12 w-12 animate-spin text-white" />
      <Typography as="h2" size="lg" color="white" className="mt-8 text-center">
        {t('nft_claim_loading')}
      </Typography>
    </div>
  ) : (
    <div className="fixed top-0 left-0 h-screen w-screen bg-black">
      <div className="absolute top-20 z-10 w-full px-4">
        <Typography as="h1" color="white" className="text-center text-[1.37rem]">
          {t('scan_qr_code')}
        </Typography>
        <Typography as="h2" size="lg" color="white" className="mt-4 text-center">
          {t('scan_qr_code_to_redeem_nft')}
        </Typography>
      </div>
      <QrReader
        delay={100}
        onError={handleError}
        onScan={handleScan}
        constraints={{ video: { facingMode: 'environment' } }}
        className="h-full w-full object-cover"
      />
      <div
        className="absolute top-1/2 left-1/2 h-72 w-72 -translate-x-1/2 -translate-y-1/2 rounded-2xl"
        style={{ boxShadow: '0 0 0 1000px rgba(0, 0, 0, 0.3)' }}
      />
    </div>
  );
};

export default Redeem;
