import type { Routes } from './types';

import {
  Certify,
  Companies,
  Company,
  Nft,
  Nfts,
  NotFound,
  Redeem,
  Transfer
} from '../pages';

const routes: Routes[] = [
  {
    name: 'nfts',
    path: ['/', '/nfts'],
    component: Nfts,
    hasHeader: true,
    actionsInOrder: ['menu', 'search'],
  },
  {
    name: 'nft',
    path: '/nft/:id',
    component: Nft,
    hasHeader: true,
    actionsInOrder: ['back'],
  },
  {
    name: 'companies',
    path: '/companies',
    component: Companies,
    hasHeader: true,
    actionsInOrder: ['menu', 'search'],
  },
  {
    name: 'company',
    path: '/company/:id',
    component: Company,
    hasHeader: true,
    actionsInOrder: ['back'],
  },
  {
    name: 'redeem',
    path: '/redeem',
    component: Redeem,
    hasHeader: true,
    actionsInOrder: ['back'],
  },
  {
    name: 'transfer',
    path: '/transfer',
    component: Transfer,
    restrictedTo: ['consumer'],
    redirect: '/sign-in',
    hasHeader: true,
    actionsInOrder: ['back'],
  },
  {
    name: 'certify',
    path: '/certify',
    component: Certify,
    restrictedTo: ['consumer'],
    redirect: '/sign-in',
    hasHeader: true,
    actionsInOrder: ['back'],
  },
  {
    name: 'not-found',
    path: '*',
    component: NotFound,
    restrictedTo: ['guest', 'consumer'],
  },
];

export default routes;
