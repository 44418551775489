import type { ReactNode } from 'react';
import { useRef } from 'react';

import { useOutsideAlerter } from '../../hooks';

const OutsideAlerter = ({
  children,
  onOutsideClick,
}: {
  children: ReactNode;
  onOutsideClick: () => void;
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onOutsideClick);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
