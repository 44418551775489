import axios from 'axios';
import { utils } from 'ethers';
import { collection, getDocs, query, where } from 'firebase/firestore';

import { rpcProvider, ipfsGateway } from '../imports/constants';

import { db } from '../imports/firebase';

import type { Contract, Nft } from '../imports/types';

const getOwnedNFTs = async (address: string) => {
  const baseURL = `${rpcProvider}/getNFTs`;

  const ownedNftsFromChain = await (
    await axios({
      method: 'get',
      url: `${baseURL}?owner=${address}`,
    })
  ).data.ownedNfts;

  const ownedNfts = await Promise.all(
    ownedNftsFromChain.map(async (nft: any) => {
      const contractsQuery = query(
        collection(db, 'contracts'),
        where('address', '==', utils.getAddress(nft.contract.address))
      );
      const contractsQuerySnapshot = await getDocs(contractsQuery);

      const contracts: Contract[] = [];

      contractsQuerySnapshot.forEach((doc) => {
        contracts.push(doc.data() as Contract);
      });

      const contract = contracts[0];

      if (contract) {
        const nfts: Nft[] = [];

        const nftsQuery = query(
          collection(db, `contracts/${contract.id}/nfts`),
          where('id', '==', parseInt(nft.id.tokenId, 16))
        );
        const nftsQuerySnapshot = await getDocs(nftsQuery);

        nftsQuerySnapshot.forEach((doc) => {
          nfts.push(doc.data() as Nft);
        });

        const companyQuery = query(collection(db, 'users'), where('address', '==', contract.owner));
        const companyQuerySnapshot = await getDocs(companyQuery);

        const company: any[] = [];

        companyQuerySnapshot.forEach((doc) => {
          company.push(doc.data() as any);
        });

        return {
          ...nfts[0],
          image: nfts[0].image.replace('ipfs://', ipfsGateway || 'https://ipfs.io/ipfs/'),
          companyName: contract.name,
          companyId: company[0].uid,
          companyImage: company[0].profilePhoto,
          contractId: contract.id,
          contractAddress: contract.address,
          contractExpiration: contract.expiration,
          contractCreatedAt: contract.createdAt,
          contractTransfer: contract.transfer,
        };
      }

      return null;
    })
  );

  return ownedNfts;
};

const getNft = async (address: string, id: string, contractId: string) => {
  const nfts = await (await getOwnedNFTs(address)).filter((nft) => !!nft);

  return nfts.find((nft: any) => nft.id === Number(id) && nft.contractId === contractId);
};

export { getOwnedNFTs, getNft };
