import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../button/Button';
import Image from '../image/Image';
import Input from '../input/Input';
import OutsideAlerter from '../outsideAlerter/OutsideAlerter';

import { IconCircularClose, IconMenu } from '../../assets/icons';
import { ImageBack, ImageSearch } from '../../assets/images';

import type { HeaderActions } from '../../imports/types';

const ActionBack = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <button type="button" onClick={() => navigate(-1)} aria-label={t('back')}>
      <Image src={ImageBack} alt={t('back')} className="h-[1.87rem] w-[1.87rem]" />
    </button>
  );
};

type ActionMenuProps = {
  toggleSidebar: () => void;
};

const ActionMenu = ({ toggleSidebar }: ActionMenuProps) => {
  const { t } = useTranslation();

  return (
    <button type="button" onClick={toggleSidebar} aria-label={t('open_sidebar')}>
      <IconMenu className="h-6 w-6 stroke-white" />
    </button>
  );
};

type ActionSearchProps = {
  searchBar: { isOpen: boolean; search: string };
  toggleSearchBar: () => void;
  updateSearch: (searched: string) => void;
};

const ActionSearch = ({ searchBar, toggleSearchBar, updateSearch }: ActionSearchProps) => {
  const { t } = useTranslation();

  const { isOpen, search } = searchBar;

  return isOpen ? (
    <OutsideAlerter onOutsideClick={toggleSearchBar}>
      <Input
        type="text"
        value={search}
        onChange={(event) => updateSearch(event.target.value)}
        elementRight={
          <Button type="ghost" icon={IconCircularClose} action={() => updateSearch('')} />
        }
      />
    </OutsideAlerter>
  ) : (
    <button type="button" onClick={toggleSearchBar} aria-label={t('search')}>
      <Image src={ImageSearch} alt={t('search')} className="h-6 w-6" />
    </button>
  );
};

type HeaderProps = {
  actionsInOrder?: HeaderActions[];
  toggleSidebar: () => void;
  searchBar: { isOpen: boolean; search: string };
  toggleSearchBar: () => void;
  updateSearch: (searched: string) => void;
};

const Header = ({
  actionsInOrder,
  toggleSidebar,
  searchBar,
  toggleSearchBar,
  updateSearch,
}: HeaderProps) => {
  const actions = {
    menu: <ActionMenu toggleSidebar={toggleSidebar} />,
    back: <ActionBack />,
    search: (
      <ActionSearch
        searchBar={searchBar}
        toggleSearchBar={toggleSearchBar}
        updateSearch={updateSearch}
      />
    ),
  };

  return (
    <header className="fixed top-0 z-10 flex h-20 w-full items-center justify-between px-4">
      {actionsInOrder?.map((action) => (
        <Fragment key={action}>{actions[action]}</Fragment>
      ))}
    </header>
  );
};

export default Header;
