import axios, { AxiosResponse } from 'axios';
import { getIdToken, signInAnonymously } from 'firebase/auth';
import { useState } from 'react';
import { auth } from '../imports/firebase';

import { backendEndpoint } from '../imports/constants';

const ClaimNft = async (
  address: string,
  contractId: string,
  claimKey: string
): Promise<AxiosResponse<{ data: any }> | { data: any }> => {
  let user = auth.currentUser

  if (!user) {
    await signInAnonymously(auth)
      .then((userr) => {
        user = userr.user
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  }

  if (user) {
    const mintTx = await axios.post(
      `${backendEndpoint}/redeem`,
      { contractId, claimKey, userAddress: address },
      { headers: { Authorization: await getIdToken(user) } }
    );

    return mintTx;
  }

  return { data: { error: 'User not logged in' } };

  // console.log('contractId: ', contractId);
  // // console.log('wallet: ', wallet);

  // const docRef = doc(db, 'contracts', contractId); //contract = contract ID
  // const docSnapshot = await getDoc(docRef);
  // let contract: DocumentData | null = null;
  // let tokenId = null;
  // const right = new ethers.Wallet(claimKey); // get wallet from input pk

  // // sdk.setPrivateKey(wallet?.privateKey);
  // // if (privateKey) sdk.setPrivateKey(privateKey);
  // // else {
  // //   console.log('no wallet');
  // // }

  // if (!sdk.isInitialized()) {
  //   console.log('sdk not initialized');
  //   await sdk.init();
  // }
  // sdk.setPrivateKey(privateKey);
  // console.log('sdk wallet: ', sdk.getWalletAddress());
  // const wallet = sdk.getWallet(); // user wallet

  // if (docSnapshot.exists()) {
  //   // console.log('doc exists');
  //   contract = docSnapshot.data();
  //   // console.log('contract: ', contract);

  //   // console.log(
  //   //   'signers addresses: ',
  //   //   computeTree(
  //   //     contract?.signers.map((signer: { address: string; privateKey: string }) => signer.address)
  //   //   )
  //   // );
  //   contract.qrCodes.forEach((qrCode: { id: number; url: string }) => {
  //     if (qrCode.url.includes(claimKey)) tokenId = qrCode.id;
  //   });
  // } else {
  //   return null;
  // }

  // const generateProof = () => {
  //   //check if wallet, to remove typescript error on leaf
  //   // if (wallet) {
  //   const tree = computeTree(
  //     contract?.signers.map((signer: { address: string; privateKey: string }) => signer.address)
  //   );

  //   // const leaf = hashLeaf(wallet?.address).toString('hex');
  //   const leaf = hashLeaf(right.address);
  //   // console.log('leaf: ', leaf.toString('hex'));
  //   const proof = tree.getHexProof(leaf);
  //   // console.log('proof: ', proof);
  //   return proof;
  //   // }
  //   // return null;
  // };

  // if (contractId && claimKey && wallet) {
  //   console.log('preparing metaTx');

  //   // console.log('metaTx inputs: ');
  //   // console.log(sdk.getWalletAddress());
  //   // console.log(await right.signMessage(getPrefixedMessage(`${wallet.address}`))); /*signature*/
  //   // console.log(right.address);
  //   // console.log(generateProof()); /*proof */
  //   // console.log(tokenId); /*id from db based on key*/

  //   const metaTx = await sdk.prepareTransaction(
  //     {
  //       //   address: process.env[CONTRACTS.FOREVER_BAMBU.env],
  //       address: `${contract?.address}`,
  //       abi: mintAbi, // call endpoint and retrieve abi function (only the one I need)
  //       name: contract?.name,
  //       version: '1.0', // ???
  //     },
  //     'mint',
  //     [
  //       sdk.getWalletAddress(),
  //       await wallet.signMessage(getPrefixedMessage(`${wallet.address}`)) /*signature*/,
  //       right.address /* address referred to claimKey */,
  //       generateProof() /*proof */,
  //       tokenId /*id from db based on key*/,
  //     ]
  //   );

  //   console.log('executing metaTx');
  //   const requestId = await sdk.executeAsyncTransaction(metaTx, {
  //     webhookUrl: `${process.env.REACT_APP_FIREBASE_ENDPOINT}/saveReceipt`,
  //     verbose: true,
  //   }); //optionals webhook address

  //   /* create sub collection with tokenId, requestId, redeemer address, status */

  //   console.log('after execute: ', requestId);
  //   return requestId;
  // }
  // return null;
};

export default ClaimNft;
