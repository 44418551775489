/* eslint-disable import/no-extraneous-dependencies */

export const environments = {
  DEV: 'DEV',
  PROD: 'PROD',
};

export const currentEnv = process.env.REACT_APP_ENV ?? 'DEV';
export const sdkEnv = process.env.REACT_APP_SDK_ENV;
export const sdkApi = process.env.REACT_APP_SDK_API;
export const rpcProvider = process.env.REACT_APP_RPC_PROVIDER;
export const pablockTokenAddress = process.env.REACT_APP_PABLOCK_TOKEN_ADDRESS;
export const pablockMetaTransaction = process.env.REACT_APP_PABLOCK_META_TRANSACTION;
export const pablockNotarization = process.env.REACT_APP_PABLOCK_NOTARIZATION;
export const pablockNft = process.env.REACT_APP_PABLOCK_NFT;
export const pablockMultiSignFactory = process.env.REACT_APP_PABLOCK_MULTISIGN_FACTORY;
export const alchemyApiKey = process.env.REACT_APP_ALCHEMY_API_KEY;
export const firebaseEndpoint = process.env.REACT_APP_FIREBASE_ENDPOINT;
export const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
export const reactAppVerifier = process.env.REACT_APP_VERIFIER;
export const ipfsGateway = process.env.REACT_APP_IPFS_GATEWAY;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messaggingSenderId: process.env.REACT_APP_MESSAGGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

export const roles = {
  guest: 'guest',
  consumer: 'consumer',
  unverified: 'unverified',
};

// export const {
//   theme: { colors },
// } = resolveConfig(tailwindConfig);
export const colors = {
  primary: '#365089',
  secondary: '#3880E8',
  'light-primary': '#EDF5F6',
  light: '#DAEDE5',
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',
  grey: '#555555',
  'light-grey-100': '#F5F5F5',
  'light-grey-200': '#909090',
  accent: '#009E8B',
  'light-green-100': '#F2FAF9',
  'light-green-200': '#E6F6F4',
  'light-green-300': '#BFE7E2',
  'light-green-400': '#80CFC6',
  error: '#FF5F5F',
  warning: '#FF985F',
  success: '#5F9FFF',
  'light-grey': '#EEEEEE',
  'light-blue-100': '#E4F3FD',
  'light-blue-200': '#C1D4E9',
  'light-blue-300': '#9DB6D5',
  'light-blue-400': '#779AC2',
  'light-blue-500': '#4F7FAF',
  'dark-blue-100': '#1E5682',
  'dark-blue-200': '#1B4669',
  'dark-blue-300': '#173853',
  'dark-blue-400': '#122A3E',
  'dark-blue-500': '#0B1C2A',
  overlay: 'rgba(0, 0, 0, 0.75)',
  tertiary: '#62B8AF',
  skeleton: '#e4e9f1',
  dark: '#211B2B',
  blue: '#375089',
};

export const textColors = {
  primary: 'text-primary',
  secondary: 'text-secondary',
  white: 'text-white',
  black: 'text-black',
  grey: 'text-grey',
  'light-grey': 'text-light-grey',
  accent: 'text-accent',
  'light-blue-100': 'text-light-blue-100',
  'light-blue-200': 'text-light-blue-200',
  'light-blue-300': 'text-light-blue-300',
  'light-blue-400': 'text-light-blue-400',
  'light-blue-500': 'text-light-blue-500',
  'dark-blue-100': 'text-dark-blue-100',
  'dark-blue-200': 'text-dark-blue-200',
  'dark-blue-300': 'text-dark-blue-300',
  'dark-blue-400': 'text-dark-blue-400',
  'dark-blue-500': 'text-dark-blue-500',
  inherit: 'text-inherit',
  error: 'text-error',
  success: 'text-success',
};

export const fillColors = {
  primary: 'fill-primary',
  secondary: 'fill-secondary',
  white: 'fill-white',
  black: 'fill-black',
  grey: 'fill-grey',
  'light-grey': 'fill-light-grey',
  accent: 'fill-accent',
  'light-blue-100': 'fill-light-blue-100',
  'light-blue-200': 'fill-light-blue-200',
  'light-blue-300': 'fill-light-blue-300',
  'light-blue-400': 'fill-light-blue-400',
  'light-blue-500': 'fill-light-blue-500',
  'dark-blue-100': 'fill-dark-blue-100',
  'dark-blue-200': 'fill-dark-blue-200',
  'dark-blue-300': 'fill-dark-blue-300',
  'dark-blue-400': 'fill-dark-blue-400',
  'dark-blue-500': 'fill-dark-blue-500',
  inherit: 'fill-inherit',
  error: 'fill-error',
  success: 'fill-success',
};

export const sidebarMenuOrderList = ['nfts', 'redeem'];
