import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Location as ReactLocation } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SimpleBar from 'simplebar-react';

import { Button, Image, Typography } from '../../components';

import { useCopyToClipboard } from '../../hooks';
import { reactAppVerifier } from '../../imports/constants';
import { getNft } from '../../utils/getNFT';

import { IconLoading } from '../../assets/icons';
import { getWallet } from '../../imports/utils';

interface Location<State> extends Omit<ReactLocation, 'state'> {
  state: State;
}

type CustomState = {
  nftId?: string;
  contractId?: string;
};

const Certify = () => {
  const { t } = useTranslation();

  const wallet = getWallet();
  const [stateCopyToClipboard, copyToClipboard] = useCopyToClipboard();

  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQrCode] = useState<{
    code?: string;
    image?: string;
    signature?: string;
    random?: number;
  }>({});

  const navigate = useNavigate();
  const { state } = useLocation() as Location<CustomState>;

  const contractId = state?.contractId;
  const nftId = state?.nftId;

  const fetchNft = async () => {
    if (wallet && nftId && contractId) {
      setIsLoading(true);

      const { address, privateKey } = wallet;

      const nft = await getNft(address, nftId, contractId);

      if (!nft) {
        navigate('/nfts');
      }

      const message = `I own the nft nr. ${nftId} of smart contract ${nft?.contractAddress}.`;

      try {
        const signature = await wallet?.signMessage(message);

        if (signature) {
          const salt = Math.round(Date.now() / 1000);

          const qrCode = `${reactAppVerifier}/nft/collection/${nft?.contractId}?tokenId=${nftId}&signature=${signature}&address=${address}&rnd=${salt}`;

          setQrCode({
            code: qrCode,
            image: await QRCode.toDataURL(qrCode),
            signature,
            random: salt,
          });
        }
      } catch (error) {
        navigate('/nfts');
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!nftId) {
      navigate('/nfts');
      return;
    }

    fetchNft();
  }, []);

  useEffect(() => {
    if (stateCopyToClipboard.value) {
      toast.success(t('qr_code_copied_successfully'), { toastId: 'copy-to-clipboard' });
    }
  }, [stateCopyToClipboard]);

  return isLoading ? (
    <div className="flex h-full w-full items-center justify-center">
      <IconLoading className="h-12 w-12 animate-spin text-white" />
    </div>
  ) : (
    <SimpleBar className="absolute top-20 h-[calc(100%-5rem)] w-full">
      <div className="flex min-h-full flex-col items-center">
        <Typography as="h2" color="white" size="2xl" weight="semibold" className="text-center">
          {t('certify_ownership_page_title')}
        </Typography>
        <Image src={qrCode.image} alt={t('qr_code')} className="mt-14 h-64 w-64 rounded-xl" />
        <div className="grow" />
        <Button action={() => copyToClipboard(qrCode.code!)} className="my-12 w-[17rem]">
          {t('copy_qr_code_to_clipboard')}
        </Button>
      </div>
    </SimpleBar>
  );
};

export default Certify;
