import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Button, Header, OverlayDialog, Sidebar, Typography } from '../components';

import { exportTextAsFile, getWallet } from '../imports/utils';

import type { DefaultPageProps, Routes } from '../imports/types';

import 'react-toastify/dist/ReactToastify.css';

const Background = () => (
  <div className="fixed top-0 left-0 -z-10 h-screen w-screen bg-dark">
    <svg
      viewBox="0 0 428 348"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute right-0 top-0 h-[348px] w-full -translate-y-1/4"
    >
      <g filter="url(#filter0_f_1349_7066)">
        <ellipse cx="303.5" cy="-19.5" rx="196.5" ry="72.5" fill="#365089" />
      </g>
      <defs>
        <filter
          id="filter0_f_1349_7066"
          x="-188"
          y="-387"
          width="983"
          height="735"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="147.5" result="effect1_foregroundBlur_1349_7066" />
        </filter>
      </defs>
    </svg>
    <svg
      viewBox="0 0 428 439"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute left-0 bottom-0 h-[439px] w-full translate-y-1/3"
    >
      <g filter="url(#filter0_f_1349_7065)">
        <ellipse cx="69.5" cy="471.5" rx="196.5" ry="176.5" fill="#365089" />
      </g>
      <defs>
        <filter
          id="filter0_f_1349_7065"
          x="-422"
          y="0"
          width="983"
          height="943"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="147.5" result="effect1_foregroundBlur_1349_7065" />
        </filter>
      </defs>
    </svg>
  </div>
);

type LayoutProps = {
  route: Routes;
};

const Layout = ({ route }: LayoutProps) => {
  const { t } = useTranslation();
  const { component, restrictedTo, redirectByRole, redirect, hasHeader, actionsInOrder } = route;

  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  const wallet = getWallet();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchBar, setSearchBar] = useState({ isOpen: false, search: '' });
  const [isWalletDialogOpen, setIsWalletDialogOpen] = useState(false);

  const Page: ({ searchBar }: DefaultPageProps) => JSX.Element = component;
  const hasRouteSidebar = actionsInOrder?.includes('menu');

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen);
  };

  const toggleSearchBar = () => {
    setSearchBar(({ isOpen, search }) => ({ isOpen: !isOpen, search }));
  };

  const updateSearch = (searched: string) => {
    setSearchBar(({ isOpen, search }) => ({ isOpen, search: searched }));
  };

  const toggleWalletDialog = () => {
    setIsWalletDialogOpen((isWalletDialogOpen) => !isWalletDialogOpen);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      toggleSidebar();
    }
  }, [pathname]);

  return (
    <div id="app" className="h-screen w-full font-sans">
      <Background />

      {hasHeader && (
        <Header
          actionsInOrder={actionsInOrder}
          toggleSidebar={toggleSidebar}
          searchBar={searchBar}
          toggleSearchBar={toggleSearchBar}
          updateSearch={updateSearch}
        />
      )}
      {hasRouteSidebar && (
        <Sidebar
          isOpen={isSidebarOpen}
          handleClose={handleSidebarClose}
          toggleWalletDialog={toggleWalletDialog}
        />
      )}
      <OverlayDialog
        isOpen={isWalletDialogOpen}
        title={t('wallet')}
        handleClose={toggleWalletDialog}
      >
        {wallet && (
          <>
            <Typography as="h3" size="lg" className="text-center">
              {t('show_mnemonic_phrase_title.this_is_your_mnemonic_phrase')}
            </Typography>
            <Typography as="h4" size="md" className="mt-4 text-center">
              {t('show_mnemonic_phrase_title.be_careful')}
            </Typography>
            <div className="mt-10 grid grid-cols-3 gap-x-4 gap-y-3">
              {wallet.mnemonic.phrase.split(' ').map((word, index) => (
                <span
                  className="w-full rounded-xl bg-word py-1 text-center text-white/90"
                  key={word}
                >
                  {`${index}. ${word}`}
                </span>
              ))}
            </div>
            <Button action={() => exportTextAsFile(wallet.mnemonic.phrase)} className="mt-8">
              {t('export_secret_phrase')}
            </Button>
          </>
        )}
      </OverlayDialog>

      <Page searchBar={searchBar} />

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Layout;
