import { useTranslation } from 'react-i18next';

import Image from '../image/Image';
import Typography from '../typography/Typography';

import { IconMore } from '../../assets/icons';

type CardCompanyProps = {
  name: string;
  image?: string;
  numberOfNfts: number;
};

const CardCompany = ({ name, image, numberOfNfts }: CardCompanyProps) => {
  const { t } = useTranslation();

  return (
    <article className="bg-glass border-glass relative flex h-full w-full items-center justify-between overflow-hidden rounded-3xl px-4 shadow-2xl">
      <div className="border-glass ml-2 h-[5.5rem] w-[5.5rem] overflow-hidden rounded-xl bg-dark">
        {image && <Image src={image} alt={name} className="h-full w-full object-cover" />}
      </div>

      <div className="ml-4 grow space-y-1">
        <Typography as="h2" color="white" size="lg" weight="semibold">
          {name}
        </Typography>
        <Typography as="h2" color="white">
          {`${numberOfNfts} ${t('nfts', { count: numberOfNfts })}`}
        </Typography>
      </div>
      <button type="button" aria-label={t('more')} onClick={() => {}}>
        <IconMore className="h-5 w-5 rotate-90 fill-white" />
      </button>
    </article>
  );
};

export default CardCompany;
